import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import IconFactory from 'src/components/factories/IconFactory';
import { SideMenuItem } from '@veneer/core';
import matchPath from 'src/utils/matchPath';
import createId from 'src/utils/createId';
import * as T from './types';
import { useRootContext } from 'src/contexts/Root';
import useFilterSubMenus from './hooks/useFilterSubMenus';
import LoaderFactory from 'src/components/factories/LoaderFactory';
import { ACTIONS, triggerMenuItemClickEvent } from 'src/utils/analytics';
import * as S from './styles';

const RecursiveMenuItemContent: T.RecursiveMenuItemContentType = ({
  analyticsControlName,
  iconReference,
  path,
  subMenus,
  label,
  exact,
  onClick,

  // Veneer need it to add the styles
  ...props
}) => {
  const { navigation } = useRootContext();
  const [isSelected, setIsSelected] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const haveSubmenus = subMenus?.length > 0;
  const allowedToRedirect = !haveSubmenus && !!path;

  const handleClick = () => {
    onClick();
  };

  const dispatchClickEvent = () => {
    const currentPath = window.Shell.v1.navigation?.location?.pathname;

    // It blocks/reduce repeated events when the clicked menu item is already selected
    if (currentPath !== path) {
      let action = ACTIONS.CONTROL_HYPERLINK_CLICKED;
      let controlDetail = Array.isArray(path) ? path?.[0] : path;

      if (haveSubmenus) {
        action = isExpanded
          ? ACTIONS.CONTROL_ACCORDIAN_COLLAPSED
          : ACTIONS.CONTROL_ACCORDIAN_EXPANDED;

        controlDetail = null;
      }

      triggerMenuItemClickEvent(action, analyticsControlName, controlDetail);
    }
  };

  const handleProperClick = (e: MouseEvent) => {
    dispatchClickEvent();
    if (!isSelected && !haveSubmenus) {
      handleClick();
    }
    e.preventDefault();
    if (allowedToRedirect) {
      navigation.push(Array.isArray(path) ? path?.[0] : path);
    }
    if (haveSubmenus) {
      setIsExpanded(!isExpanded);
    }
  };

  // checking if should be selected by its own path
  useEffect(() => {
    const updateIsSelected = () => {
      const result = !!matchPath(path, {
        pathToCompare: navigation.location.pathname,
        exact
      });

      setIsSelected(result);
    };

    const removeListener = navigation.listen(updateIsSelected);
    updateIsSelected();

    return () => removeListener();
  }, [navigation, path, exact]);

  const subPaths = useMemo(() => {
    return subMenus?.map?.((menu) => {
      if (menu.path) {
        return Array.isArray(menu.path) ? menu.path?.[0] : menu.path;
      }
    });
  }, [subMenus]);

  // Only for routes that have submenus
  // menu selection using the chidren paths
  useEffect(() => {
    if (haveSubmenus) {
      // children paths

      const updateSubMenuIsSelected = () => {
        const result = !!matchPath(subPaths, {
          pathToCompare: navigation.location.pathname,
          exact
        });
        setIsSelected(result);
      };

      const removeSubMenuListener = navigation.listen(updateSubMenuIsSelected);
      updateSubMenuIsSelected();

      return () => removeSubMenuListener();
    }
  }, [navigation, haveSubmenus, subPaths, exact]);

  useEffect(() => {
    if (isSelected && haveSubmenus) {
      setIsExpanded(true);
    }
  }, [isSelected, haveSubmenus]);

  return (
    <SideMenuItem
      {...props}
      icon={
        <IconFactory
          iconReference={iconReference}
          data-testid={createId(`side-menu-item-icon`)}
          forceFilled={isSelected}
        />
      }
      expandable={false}
      expanded={isExpanded}
      link={
        <a
          data-testid={createId(`side-menu-item-link`)}
          onClick={handleProperClick}
        />
      }
      selected={isSelected}
      label={label}
    >
      {haveSubmenus &&
        subMenus?.map?.((menu, index) => (
          <RecursiveMenuItemLoader
            {...menu}
            data-testid={createId(`side-submenu-item-${index}`)}
            key={`${index}`}
            onClick={handleClick}
          />
        ))}
    </SideMenuItem>
  );
};

const RecursiveMenuItemLoader: T.RecursiveMenuItemType = ({
  subMenus: subMenusProps,
  criterionKey,
  text,
  iconReference,
  path,
  exact,
  onClick
}) => {
  const { criterion, globalTranslate } = useRootContext();
  const [isAllowed, setIsAllowed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleClick = () => {
    onClick();
  };
  useEffect(() => {
    if (criterionKey) {
      const checkCriterion = async () => {
        const result = await criterion.checkByCriterionKey(criterionKey);
        setIsAllowed(result);
        setIsLoaded(true);
      };

      checkCriterion();
      const removeListener = criterion?.listen?.(checkCriterion);
      return () => removeListener?.();
    } else {
      setIsAllowed(true);
      setIsLoaded(true);
    }
  }, [criterion, criterionKey]);

  const filteredSubMenusObject = useFilterSubMenus({
    enable: isAllowed,
    subMenus: subMenusProps
  });

  const translatedLabel = React.useMemo(() => {
    return globalTranslate(text?.translationKey, text?.value);
  }, [globalTranslate, text]);

  const analyticsControlName = React.useMemo(() => {
    // Spaces and hyphens are replaced with an empty string
    return `Menu${text?.value?.replace(/[\s-]/g, '')}`;
  }, [text?.value]);

  if (!isLoaded || !filteredSubMenusObject?.isLoaded) {
    return (
      <S.LoaderWrapper>
        <LoaderFactory
          data-testid={createId(`side-menu-item-skeleton`)}
          appearance="skeleton"
          properties={{
            height: 30,
            width: 228
          }}
        />
      </S.LoaderWrapper>
    );
  } else if (isAllowed) {
    return (
      <RecursiveMenuItemContent
        analyticsControlName={analyticsControlName}
        data-testid={createId(`side-menu-item-${text.value}`)}
        label={translatedLabel}
        iconReference={iconReference}
        path={path}
        subMenus={filteredSubMenusObject?.subMenus}
        exact={exact}
        onClick={handleClick}
      />
    );
  }
  return null;
};

export default RecursiveMenuItemLoader;
